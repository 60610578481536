import { formatShortDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { DateType, DateTypeId, TimeType, TimeTypeId } from 'state/forms/newDriversCover/state';

export const dateOptions = [
  { name: `Today (${formatShortDate(new Date())})`, value: DateTypeId.TODAY },
  { name: DateType.CUSTOM_DATE, value: DateTypeId.CUSTOM_DATE },
];

export const startTimeOptionsCustomDate = [
  { name: TimeType.START_OF_DAY, value: TimeTypeId.START_OF_DAY },
  { name: TimeType.CUSTOM_TIME, value: TimeTypeId.CUSTOM_TIME },
];

export const startTimeOptionsToday = [
  { name: TimeType.NOW, value: TimeTypeId.START_OF_DAY },
  { name: TimeType.CUSTOM_TIME, value: TimeTypeId.CUSTOM_TIME },
];

export const endTimeOptions = [
  { name: TimeType.END_OF_DAY, value: TimeTypeId.END_OF_DAY },
  { name: TimeType.CUSTOM_TIME, value: TimeTypeId.CUSTOM_TIME },
];

export const hoursOptions = [
  { name: '00', value: '00' },
  { name: '01', value: '01' },
  { name: '02', value: '02' },
  { name: '03', value: '03' },
  { name: '04', value: '04' },
  { name: '05', value: '05' },
  { name: '06', value: '06' },
  { name: '07', value: '07' },
  { name: '08', value: '08' },
  { name: '09', value: '09' },
  { name: '10', value: '10' },
  { name: '11', value: '11' },
  { name: '12', value: '12' },
  { name: '13', value: '13' },
  { name: '14', value: '14' },
  { name: '15', value: '15' },
  { name: '16', value: '16' },
  { name: '17', value: '17' },
  { name: '18', value: '18' },
  { name: '19', value: '19' },
  { name: '20', value: '20' },
  { name: '21', value: '21' },
  { name: '22', value: '22' },
  { name: '23', value: '23' },
];

export const minutesOptions = [
  { name: '00', value: '00' },
  { name: '15', value: '15' },
  { name: '30', value: '30' },
  { name: '45', value: '45' },
];
