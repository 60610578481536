import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion, CsQuestionWithTimePlaceholders } from 'types/contentStack';
import { Question, QuestionWithTimePlaceholders } from 'types/forms';

export type NewDriversCoverQuestions = {
  selectCustomStartDate: Question;
  startDate: Question;
  selectCustomStartTime: Question;
  startTime: QuestionWithTimePlaceholders;
  selectCustomEndDate: Question;
  endDate: Question;
  selectCustomEndTime: Question;
  endTime: QuestionWithTimePlaceholders;
};

type CsNewDriversCoverQuestions = {
  csAddDriverNewDriversCoverQuestions: {
    select_custom_start_date: CsQuestion;
    start_date: CsQuestion;
    select_custom_start_time: CsQuestion;
    start_time: CsQuestionWithTimePlaceholders;
    select_custom_end_date: CsQuestion;
    end_date: CsQuestion;
    select_custom_end_time: CsQuestion;
    end_time: CsQuestionWithTimePlaceholders;
  };
};

const query = graphql`
  query {
    csAddDriverNewDriversCoverQuestions {
      select_custom_start_date {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
      }
      start_date {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
      }
      select_custom_start_time {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
      }
      start_time {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
        placeholders {
          hours
          minutes
        }
      }
      select_custom_end_date {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
      }
      end_date {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
      }
      select_custom_end_time {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
      }
      end_time {
        main_details {
          question_text
          explanatory_text
          tooltip {
            body
            button_text {
              open
              close
            }
          }
          alert_text
        }
        placeholders {
          hours
          minutes
        }
      }
    }
  }
`;

const useNewDriversCoverQuestions = (): NewDriversCoverQuestions => {
  const csQuestions = useStaticQuery<CsNewDriversCoverQuestions>(query);
  const { processQuestion, processTimeQuestion } = useQuestionProcessor();

  return {
    selectCustomStartDate: processQuestion(
      csQuestions.csAddDriverNewDriversCoverQuestions.select_custom_start_date
    ),
    startDate: processQuestion(csQuestions.csAddDriverNewDriversCoverQuestions.start_date),
    selectCustomStartTime: processQuestion(
      csQuestions.csAddDriverNewDriversCoverQuestions.select_custom_start_time
    ),
    startTime: processTimeQuestion(csQuestions.csAddDriverNewDriversCoverQuestions.start_time),
    selectCustomEndDate: processQuestion(
      csQuestions.csAddDriverNewDriversCoverQuestions.select_custom_end_date
    ),
    endDate: processQuestion(csQuestions.csAddDriverNewDriversCoverQuestions.end_date),
    selectCustomEndTime: processQuestion(
      csQuestions.csAddDriverNewDriversCoverQuestions.select_custom_end_time
    ),
    endTime: processTimeQuestion(csQuestions.csAddDriverNewDriversCoverQuestions.end_time),
  };
};

export default useNewDriversCoverQuestions;
