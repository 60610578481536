import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import FormHeading from 'components/Forms/FormHeading';
import FormPageWrapper from 'components/Forms/FormPageWrapper';
import NewDriversCoverForm from 'components/Forms/NewDriversCover';
import Layout from 'components/Layout';
import { useFormRedirect } from 'helpers/useFormRedirect';
import { DriverType } from 'types/forms';

type NewDriversCoverContentProps = {
  data: NewDriversCoverData;
};

type NewDriversCoverPageProps = NewDriversCoverContentProps & {
  location: Location;
};

const NewDriversCoverPage: React.FC<NewDriversCoverPageProps> = ({ data, location }) => {
  useFormRedirect();
  const page = data.csAddDriverNewDriversCover;
  return (
    <FormPageWrapper
      location={location}
      title={page.title}
      formPageName="newDriversCover"
      driverType={DriverType.TEMPORARY}>
      <Layout pageType="form" meta={{ title: page.title }}>
        <FormHeading text={page.heading} />
        <NewDriversCoverForm />
      </Layout>
    </FormPageWrapper>
  );
};

export default NewDriversCoverPage;

type NewDriversCoverData = {
  csAddDriverNewDriversCover: {
    title: string;
    heading: string;
  };
};

export const query = graphql`
  query {
    csAddDriverNewDriversCover {
      title
      heading
    }
  }
`;
