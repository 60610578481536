import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';

export const InputContainer = styled.div<{ inputsInlined?: boolean }>`
  ${({ inputsInlined }) =>
    !inputsInlined &&
    css`
      display: flex;
      > * {
        width: 50%;

        :first-child {
          margin-right: ${spacing(2)};
        }
      }
    `};
`;
