import { KnockoutOverridesState } from 'state/forms/knockoutOverrides/state';

export type KnockoutOverridesData = KnockoutOverridesState;

export type AboutTheDriverData = {
  isHirer: boolean;
  isNominee: boolean | null;
  refusedMotorInsurance: boolean;
};

export type AboutTheDriverRequest = {
  aboutTheDriver: AboutTheDriverData;
  coverDates?: NewDriversCoverData;
  overridableKnockouts: KnockoutOverridesData;
};

export type DriverDetailsData = {
  fullName: string;
  dateOfBirth: string;
  isAddressSameAsHirer: boolean;
};

export type DriverDetailsRequest = {
  aboutTheDriver: AboutTheDriverData;
  driverDetails: DriverDetailsData;
  replacedDriverRef: string | null;
  coverDates?: NewDriversCoverData;
  overridableKnockouts: KnockoutOverridesData;
};

export type BanData = {
  banDuration: number;
  banDurationType: string;
};

export type DrivingOffenceData = {
  offenceDate: string;
  conviction: {
    convictionDate: string;
    convictionCode: string;
    penaltyPoints: number;
    ban: BanData | undefined;
  } | null;
};

export type DrivingOffencesRequest = {
  aboutTheDriver: AboutTheDriverData;
  driverDetails: DriverDetailsData;
  claims: ClaimData[];
  licenceDetails: LicenceDetailsData;
  drivingOffences: DrivingOffenceData[];
  coverDates?: NewDriversCoverData;
  overridableKnockouts: KnockoutOverridesData;
};

export type LicenceDetailsData = {
  hasValidLicence: boolean;
  drivingLicenceType: string;
  drivingLicenceNumber: string;
  datePassedTest: string;
};

export type LicenceDetailsRequest = {
  aboutTheDriver: AboutTheDriverData;
  driverDetails: DriverDetailsData;
  licenceDetails: LicenceDetailsData;
  replacedDriverRef: string | null;
  coverDates?: NewDriversCoverData;
  overridableKnockouts: KnockoutOverridesData;
};

export type ClaimData = {
  incidentDate: string;
  incidentType: string;
  incidentDetails: string;
};

export type ClaimsHistoryRequest = {
  aboutTheDriver: AboutTheDriverData;
  driverDetails: DriverDetailsData;
  licenceDetails: LicenceDetailsData;
  claims: ClaimData[];
  coverDates?: NewDriversCoverData;
  overridableKnockouts: KnockoutOverridesData;
};

export type LicenceRestrictionRequest = {
  aboutTheDriver: AboutTheDriverData;
  driverDetails: DriverDetailsData;
  claims: ClaimData[];
  drivingOffences: DrivingOffenceData[];
  licenceDetails: LicenceDetailsData;
  licenceRestrictions: string[];
  coverDates?: NewDriversCoverData;
  overridableKnockouts: KnockoutOverridesData;
};

export type NewDriversCoverData = {
  isCoverStartNow: boolean;
  coverStart?: string;
  coverEnd: string;
};

export type NewDriversCoverRequest = {
  coverDates: NewDriversCoverData;
  overridableKnockouts: KnockoutOverridesData;
};

export type AddDriverRequest = {
  aboutTheDriver: AboutTheDriverData;
  driverDetails: DriverDetailsData;
  claims: ClaimData[];
  drivingOffences: DrivingOffenceData[];
  licenceDetails: LicenceDetailsData;
  licenceRestrictions: string[];
  overridableKnockouts: KnockoutOverridesData;
};

export type ReplaceDriverRequest = AddDriverRequest & {
  replacedDriverRef: string;
};

export type AddTemporaryDriverRequest = AddDriverRequest & {
  coverDates: NewDriversCoverData;
  replacedDriverRef?: string;
};

export type PageName =
  | 'beforeYouStart'
  | 'confirmAddress'
  | 'driverConsent'
  | 'aboutTheDriver'
  | 'driverDetails'
  | 'licenceDetails'
  | 'claimsHistory'
  | 'drivingOffences'
  | 'licenceRestrictions'
  | 'temporaryDriverCoverDates'
  | 'permanentDriverAgreement'
  | 'hirerOrNomineeAgreement'
  | 'confirmDriverDetails';

export enum SeatAvailabilityStatus {
  Free = 'Free',
  Replaceable = 'Replaceable',
  Full = 'Full',
}

export type PolicySeatStatusApiResponse = {
  status: SeatAvailabilityStatus;
  replacementReferences: string[] | null;
};
