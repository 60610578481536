import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import RadioInput, {
  Option as RadioOption,
} from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { Orientation } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { Option as SelectOption } from '@rsa-digital/evo-shared-components/components/Form/SelectInput';
import { defaultLabelId } from '@rsa-digital/evo-shared-components/components/Form/idHelpers';
import { BooleanField } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import driversService from 'apiHelpers/drivers/service';
import { SeatAvailabilityStatus } from 'apiHelpers/drivers/types';
import { navigate } from 'gatsby';
import React from 'react';
import { useKnockoutOverrides } from 'state/forms/knockoutOverrides/action';
import { useNewDriversCover } from 'state/forms/newDriversCover/action';
import { DateTypeId, NewDriversCoverState, TimeTypeId } from 'state/forms/newDriversCover/state';
import { useReplaceDriver } from 'state/forms/replaceDriver/action';
import { useSeatStatus } from 'state/seatStatus/action';
import FormFooter from 'components/Forms/FormFooter';
import QuestionField from 'components/QuestionField';
import QuestionForMultipleInputs from 'components/QuestionForMultipleInputs';
import SelectInputWithHiddenLabel from 'components/SelectInputWithHiddenLabel';
import {
  trackDropdownSelect,
  trackFieldError,
  trackRadioButtonClick,
  trackSelectOrTypeaheadFocus,
  trackTextInputFocus,
} from 'helpers/eventTracking';
import {
  dateOptions,
  endTimeOptions,
  hoursOptions,
  minutesOptions,
  startTimeOptionsCustomDate,
  startTimeOptionsToday,
} from 'helpers/formOptions/newDriversCover';
import { scrollAndTrackError } from 'helpers/forms';
import { FormPage, getFormPageRoute } from 'helpers/routingHelper';
import usePolicyNumber from 'helpers/usePolicyNumber';
import useRequestHandler from 'helpers/useRequestHandler';
import { DriverType } from 'types/forms';
import useNewDriversCoverQuestions from './questions';
import useNewDriversCoverRules from './validation';

const NewDriversCoverForm: React.FC = () => {
  const [, updateSeatStatus] = useSeatStatus();
  const [, updateReplaceDriverState] = useReplaceDriver();
  const [newDriversCover, updateNewDriversCover] = useNewDriversCover();
  const [knockoutOverrides] = useKnockoutOverrides();
  const handleRequest = useRequestHandler();
  const rules = useNewDriversCoverRules();
  const questions = useNewDriversCoverQuestions();
  const { getError, validateOnSubmit, showValidation } = useValidation(
    newDriversCover,
    rules,
    trackFieldError
  );
  const policyNumber = usePolicyNumber();

  const handleSuccess = async (): Promise<void> => {
    return handleRequest(async () => {
      await driversService.validateAddDriverRequestNewDriversCover(
        policyNumber,
        newDriversCover,
        knockoutOverrides
      );
      const seatStatus = await driversService.getSeatStatus(policyNumber, newDriversCover);
      updateSeatStatus(seatStatus);
      if (seatStatus.status === SeatAvailabilityStatus.Replaceable) {
        navigate(getFormPageRoute(DriverType.TEMPORARY, FormPage.DriverToReplace));
      } else {
        // Update the driver replaced state in case the user had navigated back to this page from the driver to replace form
        updateReplaceDriverState({
          replacedDriverRef: '',
        });
        navigate(getFormPageRoute(DriverType.TEMPORARY, FormPage.AboutTheDriver));
      }
    });
  };

  const getIsCustomDate = (
    id: BooleanField<NewDriversCoverState>,
    options: RadioOption[] | undefined
  ): string => {
    if (newDriversCover[id] === undefined) {
      return '';
    }
    const selected = newDriversCover[id] === true ? DateTypeId.CUSTOM_DATE : DateTypeId.TODAY;
    return options?.find((option) => option.value === selected)?.value ?? '';
  };

  const getIsCustomTime = (
    id: BooleanField<NewDriversCoverState>,
    secondOption: TimeTypeId,
    options: RadioOption[] | undefined
  ): string => {
    if (newDriversCover[id] === undefined) {
      return '';
    }
    const selected = newDriversCover[id] === true ? TimeTypeId.CUSTOM_TIME : secondOption;
    return options?.find((option) => option.value === selected)?.value ?? '';
  };

  const getSelectedTimeValue = (
    timeFieldId: 'startTime' | 'endTime',
    hoursOrMinutes: 'hour' | 'minute',
    options: SelectOption[] | undefined
  ): string => {
    return (
      options?.find((option) => option.value === newDriversCover[timeFieldId][hoursOrMinutes])
        ?.value ?? ''
    );
  };

  const startTimeOptions =
    newDriversCover.selectCustomStartDate === false
      ? startTimeOptionsToday
      : startTimeOptionsCustomDate;

  return (
    <form onSubmit={validateOnSubmit(handleSuccess, scrollAndTrackError)}>
      <QuestionField
        question={questions.selectCustomStartDate}
        errorText={getError('selectCustomStartDate')}>
        <RadioInput
          id="selectCustomStartDate"
          options={dateOptions}
          value={getIsCustomDate('selectCustomStartDate', dateOptions)}
          onChange={(event) => {
            updateNewDriversCover({
              selectCustomStartDate: event.target.value === DateTypeId.CUSTOM_DATE,
              selectCustomStartTime: undefined,
            });
            showValidation('selectCustomStartDate');
            trackRadioButtonClick('selectCustomStartDate', event.target.value);
          }}
          orientationOverride={Orientation.vertical}
        />
      </QuestionField>
      {newDriversCover.selectCustomStartDate && (
        <QuestionField question={questions.startDate} errorText={getError('startDate')}>
          <DateInput
            id="startDate"
            value={newDriversCover.startDate}
            onChange={(value) =>
              updateNewDriversCover({
                startDate: value,
              })
            }
            onBlur={() => showValidation('startDate')}
            onFocus={trackTextInputFocus('startDate')}
          />
        </QuestionField>
      )}
      <QuestionField
        question={questions.selectCustomStartTime}
        errorText={getError('selectCustomStartTime')}>
        <RadioInput
          id="selectCustomStartTime"
          options={startTimeOptions}
          value={getIsCustomTime(
            'selectCustomStartTime',
            TimeTypeId.START_OF_DAY,
            startTimeOptions
          )}
          onChange={(event) => {
            updateNewDriversCover({
              selectCustomStartTime: event.target.value === TimeTypeId.CUSTOM_TIME,
            });
            showValidation('selectCustomStartTime');
            trackRadioButtonClick('selectCustomStartTime', event.target.value);
          }}
          orientationOverride={Orientation.vertical}
        />
      </QuestionField>
      {newDriversCover.selectCustomStartTime && (
        <QuestionForMultipleInputs
          idPrefix="startTime"
          question={questions.startTime}
          errorText={getError('startTime')}>
          <SelectInputWithHiddenLabel
            id="startHours"
            legendId={defaultLabelId('startTime')}
            screenReaderText="Start time hour"
            value={getSelectedTimeValue('startTime', 'hour', hoursOptions)}
            options={hoursOptions ?? []}
            onChange={(event) => {
              updateNewDriversCover({
                startTime: { ...newDriversCover.startTime, hour: event.target.value },
              });
              trackDropdownSelect('startHours', event.target.value);
            }}
            onBlur={() => showValidation('startTime')}
            onFocus={trackSelectOrTypeaheadFocus('startHours')}
            placeholder={questions.startTime.placeholders.hours}
          />
          <SelectInputWithHiddenLabel
            id="startMinutes"
            legendId={defaultLabelId('startTime')}
            screenReaderText="Start time minutes"
            value={getSelectedTimeValue('startTime', 'minute', minutesOptions)}
            options={minutesOptions ?? []}
            onChange={(event) => {
              updateNewDriversCover({
                startTime: { ...newDriversCover.startTime, minute: event.target.value },
              });
              trackDropdownSelect('startMinutes', event.target.value);
            }}
            onBlur={() => showValidation('startTime')}
            onFocus={trackSelectOrTypeaheadFocus('startMinutes')}
            placeholder={questions.startTime.placeholders.minutes}
          />
        </QuestionForMultipleInputs>
      )}
      <QuestionField
        question={questions.selectCustomEndDate}
        errorText={getError('selectCustomEndDate')}>
        <RadioInput
          id="selectCustomEndDate"
          options={dateOptions}
          value={getIsCustomDate('selectCustomEndDate', dateOptions)}
          onChange={(event) => {
            updateNewDriversCover({
              selectCustomEndDate: event.target.value === DateTypeId.CUSTOM_DATE,
            });
            showValidation('selectCustomEndDate');
            trackRadioButtonClick('selectCustomEndDate', event.target.value);
          }}
          orientationOverride={Orientation.vertical}
        />
      </QuestionField>
      {newDriversCover.selectCustomEndDate && (
        <QuestionField question={questions.endDate} errorText={getError('endDate')}>
          <DateInput
            id="endDate"
            value={newDriversCover.endDate}
            onChange={(value) =>
              updateNewDriversCover({
                endDate: value,
              })
            }
            onBlur={() => showValidation('endDate')}
            onFocus={trackTextInputFocus('endDate')}
          />
        </QuestionField>
      )}
      <QuestionField
        question={questions.selectCustomEndTime}
        errorText={getError('selectCustomEndTime')}>
        <RadioInput
          id="selectCustomEndTime"
          options={endTimeOptions}
          value={getIsCustomTime('selectCustomEndTime', TimeTypeId.END_OF_DAY, endTimeOptions)}
          onChange={(event) => {
            updateNewDriversCover({
              selectCustomEndTime: event.target.value === TimeTypeId.CUSTOM_TIME,
            });
            showValidation('selectCustomEndTime');
            trackRadioButtonClick('selectCustomEndTime', event.target.value);
          }}
          orientationOverride={Orientation.vertical}
        />
      </QuestionField>
      {newDriversCover.selectCustomEndTime && (
        <QuestionForMultipleInputs
          idPrefix="endTime"
          question={questions.endTime}
          errorText={getError('endTime')}>
          <SelectInputWithHiddenLabel
            id="endHours"
            legendId={defaultLabelId('endTime')}
            screenReaderText="End time hour"
            value={getSelectedTimeValue('endTime', 'hour', hoursOptions)}
            options={hoursOptions ?? []}
            onChange={(event) => {
              updateNewDriversCover({
                endTime: { ...newDriversCover.endTime, hour: event.target.value },
              });
              trackDropdownSelect('endHours', event.target.value);
            }}
            onBlur={() => showValidation('endTime')}
            onFocus={trackSelectOrTypeaheadFocus('endHours')}
            placeholder={questions.endTime.placeholders.hours}
          />
          <SelectInputWithHiddenLabel
            id="endMinutes"
            legendId={defaultLabelId('endTime')}
            screenReaderText="End time minutes"
            value={getSelectedTimeValue('endTime', 'minute', minutesOptions)}
            options={minutesOptions ?? []}
            onChange={(event) => {
              updateNewDriversCover({
                endTime: {
                  ...newDriversCover.endTime,
                  minute: event.target.value,
                },
              });
              trackDropdownSelect('endMinutes', event.target.value);
            }}
            onBlur={() => showValidation('endTime')}
            onFocus={trackSelectOrTypeaheadFocus('endMinutes')}
            placeholder={questions.endTime.placeholders.minutes}
          />
        </QuestionForMultipleInputs>
      )}
      <FormFooter
        backButton={{
          onClick: () => navigate(getFormPageRoute(DriverType.TEMPORARY, FormPage.DriverConsent)),
        }}
      />
    </form>
  );
};

export default NewDriversCoverForm;
