import SelectInput, {
  SelectInputProps,
} from '@rsa-digital/evo-shared-components/components/Form/SelectInput';
import {
  defaultLabelId,
  joinIds,
} from '@rsa-digital/evo-shared-components/components/Form/idHelpers';
import { InputComponent } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import React from 'react';
import { HiddenLabel } from './styles';

export type SelectInputWithHiddenLabelProps = SelectInputProps & {
  screenReaderText: string;
  legendId: string;
};

const SelectInputWithHiddenLabel: InputComponent<SelectInputWithHiddenLabelProps> = ({
  id,
  screenReaderText,
  legendId,
  ...rest
}) => {
  return (
    <div>
      <SelectInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        id={id}
        aria-labelledby={joinIds(legendId, defaultLabelId(id))}
      />
      <HiddenLabel id={defaultLabelId(id)}>{screenReaderText}</HiddenLabel>
    </div>
  );
};

export default SelectInputWithHiddenLabel;
