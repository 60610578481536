import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  addDaysToDate,
  getStartOfDay,
  isDateValueValidDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  dateValueGreaterThanOrEqualTo,
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  required,
  requiredDateValue,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { isEqual } from 'lodash';
import { NewDriversCoverState } from 'state/forms/newDriversCover/state';
import {
  datesWithinXDaysOfEachOther,
  dateWithinXDaysFromNow,
  startDateBeforeEndDate,
} from 'state/forms/shared/dateValue';
import {
  dateTimesWithinXDaysOfEachOther,
  dateTimeWithinXDaysFromNow,
  hasMinimumCoverLength,
  isBefore,
  isInFuture,
  TimeValue,
} from 'state/forms/shared/timeValue';
import { useUserState } from 'state/user/state';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsDriverDetailsErrorMessages = {
  csAddDriverNewDriversCoverQuestions: {
    select_custom_start_date: CsErrorsMissingOnly;
    start_date: {
      error_messages: {
        missing: string;
        not_in_next_thirty_days: string;
        date_in_past: string;
        invalid_day: string;
        invalid_month: string;
        year_too_short: string;
        invalid_date: string;
        today_error: string;
      };
    };
    select_custom_start_time: CsErrorsMissingOnly;
    start_time: {
      error_messages: {
        missing: string;
        time_in_past: string;
        start_of_day_error: string;
      };
    };
    select_custom_end_date: CsErrorsMissingOnly;
    end_date: {
      error_messages: {
        missing: string;
        thirty_days_maximum: string;
        staff_forty_five_days_maximum: string;
        date_in_past: string;
        invalid_day: string;
        invalid_month: string;
        year_too_short: string;
        invalid_date: string;
        before_start_date: string;
        today_error: string;
      };
    };
    select_custom_end_time: CsErrorsMissingOnly;
    end_time: {
      error_messages: {
        missing: string;
        time_in_past: string;
        end_of_day_error: string;
        minimum_cover_length: string;
      };
    };
  };
};

const query = graphql`
  query {
    csAddDriverNewDriversCoverQuestions {
      select_custom_start_date {
        error_messages {
          missing
        }
      }
      start_date {
        error_messages {
          missing
          not_in_next_thirty_days
          date_in_past
          invalid_day
          invalid_month
          year_too_short
          invalid_date
          today_error
        }
      }
      select_custom_start_time {
        error_messages {
          missing
        }
      }
      start_time {
        error_messages {
          missing
          time_in_past
          start_of_day_error
        }
      }
      select_custom_end_date {
        error_messages {
          missing
        }
      }
      end_date {
        error_messages {
          missing
          thirty_days_maximum
          staff_forty_five_days_maximum
          date_in_past
          invalid_day
          invalid_month
          year_too_short
          invalid_date
          before_start_date
          today_error
        }
      }
      select_custom_end_time {
        error_messages {
          missing
        }
      }
      end_time {
        error_messages {
          missing
          time_in_past
          end_of_day_error
          minimum_cover_length
        }
      }
    }
  }
`;

const getSelectedDate = (
  selectCustomDate: boolean | undefined,
  customDate: DateValue
): DateValue => {
  return selectCustomDate
    ? customDate
    : ({
        day: new Date().getDate().toString(),
        month: (new Date().getMonth() + 1).toString(),
        year: new Date().getFullYear().toString(),
      } as DateValue);
};

const getSelectedStartTime = (
  selectCustomTime: boolean | undefined,
  customTime: TimeValue,
  isToday = false
): TimeValue => {
  const now = new Date();
  const nonCustomTime: TimeValue = isToday
    ? { hour: now.getHours().toString(), minute: now.getMinutes().toString() }
    : {
        hour: '00',
        minute: '01',
      };

  return selectCustomTime ? customTime : nonCustomTime;
};

const getSelectedEndTime = (
  selectCustomTime: boolean | undefined,
  customTime: TimeValue
): TimeValue => {
  return selectCustomTime
    ? customTime
    : ({
        hour: '23',
        minute: '59',
      } as TimeValue);
};

const coverStartMaxDaysInFuture = 30;

const useNewDriversCoverStartDateRules = (
  errorMessages: CsDriverDetailsErrorMessages
): ValidationRules<NewDriversCoverState> => {
  return {
    selectCustomStartDate: [
      required(
        errorMessages.csAddDriverNewDriversCoverQuestions.select_custom_start_date.error_messages
          .missing
      ),
    ],
    startDate: validateIf((data) => !!data.selectCustomStartDate, [
      requiredDateValue(
        errorMessages.csAddDriverNewDriversCoverQuestions.start_date.error_messages.missing
      ),
      dateValueValidDay(
        errorMessages.csAddDriverNewDriversCoverQuestions.start_date.error_messages.invalid_day
      ),
      dateValueValidMonth(
        errorMessages.csAddDriverNewDriversCoverQuestions.start_date.error_messages.invalid_month
      ),
      dateValueValidYear(
        errorMessages.csAddDriverNewDriversCoverQuestions.start_date.error_messages.year_too_short
      ),
      dateValueValid(
        errorMessages.csAddDriverNewDriversCoverQuestions.start_date.error_messages.invalid_date
      ),
      dateValueLessThanOrEqualTo(
        addDaysToDate(new Date(), coverStartMaxDaysInFuture),
        errorMessages.csAddDriverNewDriversCoverQuestions.start_date.error_messages
          .not_in_next_thirty_days
      ),
      dateValueGreaterThanOrEqualTo(
        getStartOfDay(new Date()),
        errorMessages.csAddDriverNewDriversCoverQuestions.start_date.error_messages.date_in_past
      ),
      dateValueGreaterThanOrEqualTo(
        new Date(),
        errorMessages.csAddDriverNewDriversCoverQuestions.start_date.error_messages.today_error
      ),
    ]),
    selectCustomStartTime: [
      required(
        errorMessages.csAddDriverNewDriversCoverQuestions.select_custom_start_time.error_messages
          .missing
      ),
    ],
    startTime: validateIf((data) => !!data.selectCustomStartTime, [
      {
        validityCondition: (data) => !!data.hour && !!data.minute,
        errorMessage:
          errorMessages.csAddDriverNewDriversCoverQuestions.start_time.error_messages.missing,
      },
      ...validateIf<TimeValue, NewDriversCoverState>(
        (data) => !data.selectCustomStartDate || isDateValueValidDate(data.startDate),
        [
          {
            validityCondition: (_, data) =>
              isInFuture(
                getSelectedDate(data.selectCustomStartDate, data.startDate),
                getSelectedStartTime(true, data.startTime, !data.selectCustomStartDate)
              ),
            errorMessage:
              errorMessages.csAddDriverNewDriversCoverQuestions.start_time.error_messages
                .time_in_past,
          },
        ]
      ),
      ...validateIf<TimeValue, NewDriversCoverState>(
        (data) =>
          dateWithinXDaysFromNow(
            coverStartMaxDaysInFuture,
            getSelectedDate(data.selectCustomStartDate, data.startDate)
          ),
        [
          {
            validityCondition: (_, data) =>
              dateTimeWithinXDaysFromNow(
                coverStartMaxDaysInFuture,
                getSelectedDate(data.selectCustomStartDate, data.startDate),
                getSelectedStartTime(true, data.startTime, !data.selectCustomStartDate)
              ),
            errorMessage:
              errorMessages.csAddDriverNewDriversCoverQuestions.start_date.error_messages
                .not_in_next_thirty_days,
          },
        ]
      ),
      {
        validityCondition: (data) => !(data.hour === '00' && data.minute === '00'),
        errorMessage:
          errorMessages.csAddDriverNewDriversCoverQuestions.start_time.error_messages
            .start_of_day_error,
      },
    ]),
  };
};

const useNewDriversCoverRules = (): ValidationRules<NewDriversCoverState> => {
  const errorMessages = useStaticQuery<CsDriverDetailsErrorMessages>(query);
  const { isStaffMember } = useUserState();

  const startDateRules = useNewDriversCoverStartDateRules(errorMessages);

  const isStartDateValid = (data: NewDriversCoverState): boolean =>
    (startDateRules.selectCustomStartDate || []).every((rule) =>
      rule.validityCondition(data.selectCustomStartDate, data)
    ) &&
    (startDateRules.startDate || []).every((rule) => rule.validityCondition(data.startDate, data));

  const isStartTimeValid = (data: NewDriversCoverState): boolean =>
    isStartDateValid(data) &&
    (startDateRules.selectCustomStartTime || []).every((rule) =>
      rule.validityCondition(data.selectCustomStartTime, data)
    ) &&
    (startDateRules.startTime || []).every((rule) => rule.validityCondition(data.startTime, data));

  const coverPeriodMaxLength = isStaffMember ? 45 : 30;

  return {
    ...startDateRules,
    selectCustomEndDate: [
      required(
        errorMessages.csAddDriverNewDriversCoverQuestions.select_custom_end_date.error_messages
          .missing
      ),
      ...validateIf(isStartDateValid, [
        {
          validityCondition: (_, data) =>
            !data.selectCustomEndDate
              ? startDateBeforeEndDate(
                  getSelectedDate(data.selectCustomStartDate, data.startDate),
                  getSelectedDate(data.selectCustomEndDate, data.endDate)
                )
              : true,
          errorMessage:
            errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages
              .before_start_date,
        },
      ]),
    ],
    endDate: validateIf((data) => !!data.selectCustomEndDate, [
      requiredDateValue(
        errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages.missing
      ),
      dateValueValidDay(
        errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages.invalid_day
      ),
      dateValueValidMonth(
        errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages.invalid_month
      ),
      dateValueValidYear(
        errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages.year_too_short
      ),
      dateValueValid(
        errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages.invalid_date
      ),
      ...validateIf(isStartDateValid, [
        {
          validityCondition: (_, data) =>
            startDateBeforeEndDate(
              getSelectedDate(data.selectCustomStartDate, data.startDate),
              data.endDate
            ),
          errorMessage:
            errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages
              .before_start_date,
        },
        {
          validityCondition: (_, data) =>
            datesWithinXDaysOfEachOther(
              coverPeriodMaxLength,
              getSelectedDate(data.selectCustomStartDate, data.startDate),
              data.endDate
            ),
          errorMessage: isStaffMember
            ? errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages
                .staff_forty_five_days_maximum
            : errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages
                .thirty_days_maximum,
        },
      ]),
      dateValueGreaterThanOrEqualTo(
        getStartOfDay(new Date()),
        errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages.date_in_past
      ),
      dateValueGreaterThanOrEqualTo(
        new Date(),
        errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages.today_error
      ),
    ]),
    selectCustomEndTime: [
      required(
        errorMessages.csAddDriverNewDriversCoverQuestions.select_custom_end_time.error_messages
          .missing
      ),
      ...validateIf(
        (data: NewDriversCoverState) =>
          isStartTimeValid(data) && data.selectCustomEndTime === false,
        [
          {
            validityCondition: (_, data) =>
              hasMinimumCoverLength(
                getSelectedDate(data.selectCustomStartDate, data.startDate),
                getSelectedStartTime(
                  data.selectCustomStartTime,
                  data.startTime,
                  !data.selectCustomStartDate
                ),
                getSelectedDate(data.selectCustomEndDate, data.endDate),
                getSelectedEndTime(data.selectCustomEndTime, data.endTime)
              ),
            errorMessage:
              errorMessages.csAddDriverNewDriversCoverQuestions.end_time.error_messages
                .minimum_cover_length,
          },
        ]
      ),
      ...validateIf(
        (data: NewDriversCoverState) =>
          isStartTimeValid(data) &&
          datesWithinXDaysOfEachOther(
            coverPeriodMaxLength,
            getSelectedDate(data.selectCustomStartDate, data.startDate),
            data.endDate
          ) &&
          data.selectCustomEndTime === false,
        [
          {
            validityCondition: (_, data) =>
              dateTimesWithinXDaysOfEachOther(
                coverPeriodMaxLength,
                getSelectedDate(data.selectCustomStartDate, data.startDate),
                getSelectedStartTime(
                  data.selectCustomStartTime,
                  data.startTime,
                  !data.selectCustomStartDate
                ),
                getSelectedDate(data.selectCustomEndDate, data.endDate),
                getSelectedEndTime(data.selectCustomEndTime, data.endTime)
              ),
            errorMessage: isStaffMember
              ? errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages
                  .staff_forty_five_days_maximum
              : errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages
                  .thirty_days_maximum,
          },
        ]
      ),
    ],
    endTime: validateIf((data) => !!data.selectCustomEndTime, [
      {
        validityCondition: (endTime) => !!endTime.hour && !!endTime.minute,
        errorMessage:
          errorMessages.csAddDriverNewDriversCoverQuestions.end_time.error_messages.missing,
      },
      ...validateIf<TimeValue, NewDriversCoverState>(
        (data) => !data.selectCustomEndDate || isDateValueValidDate(data.endDate),
        [
          {
            validityCondition: (_, data) =>
              isInFuture(
                getSelectedDate(data.selectCustomEndDate, data.endDate),
                getSelectedEndTime(data.selectCustomStartTime, data.endTime)
              ),
            errorMessage:
              errorMessages.csAddDriverNewDriversCoverQuestions.end_time.error_messages
                .time_in_past,
          },
        ]
      ),
      {
        validityCondition: (data) => !(data.hour === '00' && data.minute === '00'),
        errorMessage:
          errorMessages.csAddDriverNewDriversCoverQuestions.end_time.error_messages
            .end_of_day_error,
      },
      ...validateIf<TimeValue, NewDriversCoverState>(isStartTimeValid, [
        {
          validityCondition: (_, data) =>
            !isEqual(
              getSelectedDate(data.selectCustomStartDate, data.startDate),
              getSelectedDate(data.selectCustomEndDate, data.endDate)
            ) ||
            isBefore(
              getSelectedStartTime(
                data.selectCustomStartTime,
                data.startTime,
                !data.selectCustomStartDate
              ),
              getSelectedEndTime(data.selectCustomEndTime, data.endTime)
            ),
          errorMessage:
            errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages
              .before_start_date,
        },
        {
          validityCondition: (_, data) =>
            hasMinimumCoverLength(
              getSelectedDate(data.selectCustomStartDate, data.startDate),
              getSelectedStartTime(
                data.selectCustomStartTime,
                data.startTime,
                !data.selectCustomStartDate
              ),
              getSelectedDate(data.selectCustomEndDate, data.endDate),
              getSelectedEndTime(data.selectCustomEndTime, data.endTime)
            ),
          errorMessage:
            errorMessages.csAddDriverNewDriversCoverQuestions.end_time.error_messages
              .minimum_cover_length,
        },
      ]),
      ...validateIf<TimeValue, NewDriversCoverState>(
        (data: NewDriversCoverState) =>
          isStartTimeValid(data) &&
          datesWithinXDaysOfEachOther(
            coverPeriodMaxLength,
            getSelectedDate(data.selectCustomStartDate, data.startDate),
            data.endDate
          ),
        [
          {
            validityCondition: (_, data) =>
              dateTimesWithinXDaysOfEachOther(
                coverPeriodMaxLength,
                getSelectedDate(data.selectCustomStartDate, data.startDate),
                getSelectedStartTime(
                  data.selectCustomStartTime,
                  data.startTime,
                  !data.selectCustomStartDate
                ),
                getSelectedDate(data.selectCustomEndDate, data.endDate),
                getSelectedEndTime(data.selectCustomEndTime, data.endTime)
              ),
            errorMessage: isStaffMember
              ? errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages
                  .staff_forty_five_days_maximum
              : errorMessages.csAddDriverNewDriversCoverQuestions.end_date.error_messages
                  .thirty_days_maximum,
          },
        ]
      ),
    ]),
  };
};

export default useNewDriversCoverRules;
