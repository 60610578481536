import ErrorContainer from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer';
import FieldGrid from '@rsa-digital/evo-shared-components/components/Form/Field/FieldGrid';
import FieldInfo, {
  ExplanatoryText,
  Legend,
  Tooltip,
} from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo';
import {
  defaultContainerId,
  defaultErrorId,
  defaultLabelId,
} from '@rsa-digital/evo-shared-components/components/Form/idHelpers';
import { InputReactElement } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import { GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { focusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { applyComponentTheme } from '@rsa-digital/evo-shared-components/theme';
import React, { useRef } from 'react';
import { Question } from 'types/forms';
import { InputContainer } from './styles';

type InputElement = InputReactElement & React.ReactNode;

export type QuestionForMultipleInputsProps = {
  idPrefix: string;
  children: InputElement[];
  question: Question;
  errorText?: string;
  warningText?: string;
  inputsInlined?: boolean;
  gridItemProps?: GridItemProps;
} & ComponentProps;

const QuestionForMultipleInputs: React.FC<QuestionForMultipleInputsProps> = ({
  idPrefix,
  children,
  question,
  errorText,
  warningText,
  inputsInlined,
  gridItemProps,
  ...rest
}) => {
  const errorId = defaultErrorId(idPrefix);
  const containerId = defaultContainerId(idPrefix);
  const labelId = defaultLabelId(idPrefix);

  const { explanatoryText, tooltip, questionText, alertText } = question;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <ErrorContainer
      errorText={errorText}
      warningText={warningText}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...componentProps(rest)}
      fieldSet
      id={containerId}
      errorId={errorId}>
      <FieldGrid alignLeft gridItemProps={gridItemProps}>
        <FieldInfo alertBody={alertText}>
          <Legend id={labelId}>{questionText}</Legend>
          {explanatoryText && <ExplanatoryText>{explanatoryText}</ExplanatoryText>}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {tooltip && <Tooltip {...tooltip} />}
        </FieldInfo>
        <InputContainer
          inputsInlined={inputsInlined}
          id={idPrefix}
          tabIndex={-1}
          ref={ref}
          onFocus={(e) => {
            e.preventDefault();
            if (e.target === ref.current && children?.[0]?.props.id) {
              focusInput(children[0].props.id);
            }
          }}>
          {children.map((child) =>
            React.cloneElement(child, {
              'aria-describedby': errorId,
              'aria-invalid': !!errorText,
              key: child.props.id,
            })
          )}
        </InputContainer>
      </FieldGrid>
    </ErrorContainer>
  );
};

export default applyComponentTheme(QuestionForMultipleInputs, 'QuestionForMultipleInputs');
